import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { UserContextProvider } from './contexts/UserContext'
import { SectionContextProvider } from './contexts/SectionContext'
import { ProgressContextProvider } from './contexts/ProgressContext'
import { RatingContextProvider } from './contexts/RatingContext'
import { SignInContextProvider } from './contexts/SignInContext'
import { LectureMenuContextProvider } from './contexts/LectureMenuContext'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <SectionContextProvider>
          <LectureMenuContextProvider>
            <UserContextProvider>
              <ProgressContextProvider>
                <RatingContextProvider>
                  <SignInContextProvider>
                    <App />
                  </SignInContextProvider>
                </RatingContextProvider>
              </ProgressContextProvider>
            </UserContextProvider>
          </LectureMenuContextProvider>
        </SectionContextProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
