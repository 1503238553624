import React, { useContext } from 'react'
import { SectionContext } from '../contexts/SectionContext'
import { DiscussionEmbed } from 'disqus-react'

function Discussion () {
  const [sectionState, ] = useContext(SectionContext)

  return (
    <div className='row'>
      <div className='col-12 col-lg-9'>
        <DiscussionEmbed
          shortname='rabbitmq-training'
          config={{
            url: window.location.href,
            identifier: sectionState.subsection.id.toString(),
            title: sectionState.subsection.title
          }}
        />
      </div>
    </div>
  )
}

export default Discussion
