import { SaveProgress, DeleteProgress } from '../components/User'
import { Sections, Subsections } from '../components/Sections'

export const ProgressReducer = (state, action) => {
  let progress = []
  let sectionProgress = []

  switch (action.type) {
    case 'SetUserProgress':
      getProgress({ oldProgress: action.user.progress, sectionProgress })
      return { ...state, progress: action.user.progress, sectionProgress }

    case 'SetProgress':
      getProgress({ oldProgress: action.progress, sectionProgress: state.sectionProgress })
      return { ...state, progress: action.progress, sectionProgress }

    case 'AddProgress':
      let subsection = action.subsection
      progress = state.progress
      if (!progress.includes(subsection.id)) {
        SaveProgress(subsection.id)
        progress.push(subsection.id)
        sectionProgress = state.sectionProgress
        sectionProgress[findSection(action.subsection) - 1] += 1
      }
      return { ...state, progress, sectionProgress }

    case 'DeleteProgress':
      DeleteProgress(action.subsection.id)
      progress = state.progress.filter((subsection) => subsection !== action.subsection.id)
      sectionProgress = state.sectionProgress
      sectionProgress[findSection(action.subsection) - 1] -= 1
      return { ...state, progress, sectionProgress }

    case 'ResetProgress':
      DeleteProgress(null)
      sectionProgress = state.sectionProgress
      if (state.progress.includes(58)) {
        progress.push(58)
        progress.push(57)
      } else if (state.progress.includes(57)) {
        progress.push(57)
      }
      Sections.forEach(section => {
        if (section.title !== 'Examination') {
          sectionProgress[section.id - 1] = 0
        }
      })
      return { ...state, progress, sectionProgress }

    default:
      return state
  }
}

function getProgress ({ oldProgress, sectionProgress }) {
  Sections.forEach(section => {
    let progress = 0
    Subsections.forEach(subsection => {
      if (subsection.sectionId === section.id && oldProgress.includes(subsection.id)) {
        progress += 1
      }
    })
    sectionProgress[section.id - 1] = progress
  })
}

function findSection (subsection) {
  const section = Sections.find(section => section.id === subsection.sectionId)
  return section.id
}
