import React, { useContext, useEffect } from 'react'
import { UserContext } from '../contexts/UserContext'
import { UserFromCertificateId, GeneratePDF } from './User'
import { useLocation } from 'react-router-dom'
import Certificate from './Certificate'

function LoadCertificate () {
  const [user, dispatchUser] = useContext(UserContext)
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname.split('/')
    const certificate_id = path[path.length - 1]
    UserFromCertificateId(certificate_id).then(user => {
      if (user) {
        dispatchUser({ type: 'SetUser', user })
        GeneratePDF(certificate_id).then((response) => {
          const blob = new Blob([response], {type: 'application/pdf'})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `rabbitmq-certificate.pdf`
          link.click()
        })
      }
    })
  }, [location])

  if (user.certificate && user.name) {
    return (
      <Certificate />
    )
  } else {
    return null
  }
}

export default LoadCertificate
