
const Subsections = [
  { id: 1, sectionId: 1, title: 'Welcome', video: 'https://player.vimeo.com/video/461913275', duration: 180, content: 'video' },
  { id: 2, sectionId: 1, title: 'Course Introduction', video: 'https://vimeo.com/462738134/a9f7c3bd2b', duration: 120, content: 'video' },
  { id: 3, sectionId: 1, title: 'Explaining Message Queues', video: 'https://player.vimeo.com/video/463799008', duration: 180, content: 'video' },
  { id: 4, sectionId: 1, title: 'Message Queue Use Cases', video: 'https://player.vimeo.com/video/470085140', duration: 120, content: 'video' },
  { id: 5, sectionId: 1, title: 'Advantages of Message Queueing', video: 'https://player.vimeo.com/video/471146989', duration: 540, content: 'video' },
  { id: 6, sectionId: 1, title: 'The Message Queue RabbitMQ', video: 'null', duration: 180, content: 'text' },
  { id: 7, sectionId: 1, title: 'Overview of the AMQP Protocol', video: 'https://player.vimeo.com/video/472502779', duration: 360, content: 'video' },
  { id: 8, sectionId: 1, title: 'How It Is All Connected', video: 'https://player.vimeo.com/video/472921415', duration: 120, content: 'video' },
  { id: 9, sectionId: 1, title: 'Try Sending Your First Message', video: 'https://player.vimeo.com/video/478045509', duration: 120, content: 'video' },
  { id: 100, sectionId: 1, title: 'Check Your Knowledge', video: 'null', duration: 180, content: 'quiz' },

  { id: 10, sectionId: 2, title: 'Introduction', video: 'https://player.vimeo.com/video/478162100', duration: 180, content: 'video' },
  { id: 11, sectionId: 2, title: 'Connections and Channels', video: 'https://player.vimeo.com/video/478164315', duration: 180, content: 'video' },
  { id: 12, sectionId: 2, title: 'Queues', video: 'null', duration: 120, content: 'text' },
  { id: 13, sectionId: 2, title: 'Exchanges', video: 'null', duration: 120, content: 'text' },
  { id: 14, sectionId: 2, title: 'Bindings', video: 'null', duration: 60, content: 'text' },
  { id: 15, sectionId: 2, title: 'Exchange Types and Examples', video: 'https://player.vimeo.com/video/469931667', duration: 480, content: 'video' },
  { id: 16, sectionId: 2, title: 'Consumer Acknowledgements and Publisher Confirm', video: 'null', duration: 120, content: 'text' },
  { id: 17, sectionId: 2, title: 'Virtual Hosts', video: 'null', duration: 120, content: 'text' },
  { id: 99, sectionId: 2, title: 'Check Your Knowledge', video: 'null', duration: 180, content: 'quiz' },

  { id: 18, sectionId: 3, title: 'Introduction', video: 'https://player.vimeo.com/video/473031423', duration: 60, content: 'video' },
  { id: 19, sectionId: 3, title: 'Python Example Code', video: 'https://player.vimeo.com/video/473137907', duration: 120, content: 'video' },
  { id: 63, sectionId: 3, title: 'Ruby Example code', video: 'null', duration: 600, content: 'text' },
  { id: 64, sectionId: 3, title: 'NodeJS Example code', video: 'null', duration: 600, content: 'text' },
  { id: 98, sectionId: 3, title: 'Practical exercise', video: 'null', duration: 600, content: 'practical_exercise' },

  { id: 20, sectionId: 4, title: 'Introduction', video: 'https://player.vimeo.com/video/648480346?h=fa8d43c21c', duration: 120, content: 'video' },
  { id: 21, sectionId: 4, title: 'The Overview', video: 'null', duration: 120, content: 'text' },
  { id: 22, sectionId: 4, title: 'Connections and Channels', video: 'null', duration: 120, content: 'text' },
  { id: 23, sectionId: 4, title: 'Exchanges', video: 'null', duration: 60, content: 'text' },
  { id: 24, sectionId: 4, title: 'Queues and Bindings', video: 'null', duration: 120, content: 'text' },
  { id: 97, sectionId: 4, title: 'Check Your Knowledge', video: 'null', duration: 180, content: 'quiz' },

  { id: 40, sectionId: 5, title: 'Introduction', video: 'https://player.vimeo.com/video/648480506?h=9c3c60bac7', duration: 60, content: 'video' },
  { id: 41, sectionId: 5, title: 'How to Install RabbitMQ', video: 'null', duration: 240, content: 'text' },
  { id: 42, sectionId: 5, title: 'Nodes and Clustering', video: 'null', duration: 120, content: 'text' },
  { id: 43, sectionId: 5, title: 'Mirrored Queues', video: 'null', duration: 60, content: 'text' },
  { id: 44, sectionId: 5, title: 'Quorum Queues', video: 'null', duration: 60, content: 'text' },
  { id: 45, sectionId: 5, title: 'Introducing RabbitMQ Streams', video: 'null', duration: 120, content: 'text' },
  { id: 46, sectionId: 5, title: 'Lazy Queues', video: 'null', duration: 60, content: 'text' },
  { id: 47, sectionId: 5, title: 'Arguments and Properties', video: 'null', duration: 240, content: 'text' },
  { id: 48, sectionId: 5, title: 'Policies', video: 'null', duration: 240, content: 'text' },
  { id: 49, sectionId: 5, title: 'Consumer Acknowledgment', video: 'null', duration: 120, content: 'text' },
  { id: 50, sectionId: 5, title: 'Publisher Confirm', video: 'null', duration: 60, content: 'text' },
  { id: 96, sectionId: 5, title: 'Check Your Knowledge', video: 'null', duration: 180, content: 'quiz' },

  { id: 66, sectionId: 11, title: 'Streams and Replay', video: 'null', duration: 280, content: 'text' },
  { id: 67, sectionId: 11, title: 'Working with Streams', video: 'null', duration: 490, content: 'text' },
  { id: 68, sectionId: 11, title: 'Limits & Configurations', video: 'null', duration: 370, content: 'text' },

  { id: 36, sectionId: 6, title: 'Introduction', video: 'https://player.vimeo.com/video/648621357?h=d3bb498b47', duration: 60, content: 'video' },
  { id: 37, sectionId: 6, title: 'Prefetch', video: 'null', duration: 240, content: 'text' },
  { id: 38, sectionId: 6, title: 'Dead Lettering', video: 'null', duration: 180, content: 'text' },
  { id: 39, sectionId: 6, title: 'Alternate Exchange', video: 'null', duration: 240, content: 'text' },
  { id: 95, sectionId: 6, title: 'Check Your Knowledge', video: 'null', duration: 180, content: 'quiz' },

  { id: 25, sectionId: 7, title: 'Introduction', video: 'https://player.vimeo.com/video/648480430?h=6802b6bf7c', duration: 31, content: 'video' },
  { id: 65, sectionId: 7, title: 'What is a plugin?', video: 'null', duration: 410, content: 'text' },
  { id: 26, sectionId: 7, title: 'Discover and Install Available Plugins', video: 'null', duration: 105, content: 'text' },
  { id: 27, sectionId: 7, title: 'RabbitMQ Management Plugin', video: 'null', duration: 60, content: 'text' },
  { id: 28, sectionId: 7, title: 'Shovel Plugin', video: 'null', duration: 60, content: 'text' },
  { id: 29, sectionId: 7, title: 'Federation Plugin', video: 'null', duration: 60, content: 'text' },
  { id: 30, sectionId: 7, title: 'Message Timestamp', video: 'null', duration: 60, content: 'text' },
  { id: 31, sectionId: 7, title: 'Consistent Hash Exchange Type', video: 'null', duration: 60, content: 'text' },
  { id: 32, sectionId: 7, title: 'Sharding Plugin', video: 'null', duration: 60, content: 'text' },
  { id: 33, sectionId: 7, title: 'Priority Queues', video: 'null', duration: 60, content: 'text' },
  { id: 34, sectionId: 7, title: 'Delayed Message Exchange', video: 'null', duration: 110, content: 'text' },
  { id: 35, sectionId: 7, title: 'Messaging Protocols', video: 'null', duration: 60, content: 'text' },
  { id: 94, sectionId: 7, title: 'Practical Exercise', video: 'null', duration: 600, content: 'practical_exercise' },

  { id: 51, sectionId: 8, title: 'Introduction', video: 'https://player.vimeo.com/video/649393732?h=f678e9df5e', duration: 120, content: 'video' },
  { id: 52, sectionId: 8, title: 'MQTT', video: 'null', duration: 60, content: 'text' },
  { id: 53, sectionId: 8, title: 'STOMP', video: 'null', duration: 60, content: 'text' },
  { id: 54, sectionId: 8, title: 'HTTP', video: 'null', duration: 120, content: 'text' },
  { id: 55, sectionId: 8, title: 'WebStomp', video: 'null', duration: 60, content: 'text' },
  { id: 56, sectionId: 8, title: 'WebSockets (Over AMQP and MQTT)', video: 'null', duration: 120, content: 'text' },

  { id: 59, sectionId: 10, title: 'Introduction', video: 'https://player.vimeo.com/video/759148146?h=8ec7338343', duration: 60, content: 'video' },
  { id: 60, sectionId: 10, title: 'Queue Performance Optimization', video: 'null', duration: 360, content: 'text' },
  { id: 61, sectionId: 10, title: 'Connections and Channels', video: 'null', duration: 180, content: 'text' },
  { id: 62, sectionId: 10, title: 'Various tips', video: 'null', duration: 240, content: 'text' },
  { id: 93, sectionId: 10, title: 'Check Your Knowledge', video: 'null', duration: 180, content: 'quiz' },

  { id: 57, sectionId: 9, title: 'Theoretical Exam', video: 'null', duration: 360, content: 'theoretical_exam' },
  { id: 58, sectionId: 9, title: 'Practical Exam', video: 'null', duration: 900, content: 'practical_exam' }
]

const Sections = [
  { id: 1, title: 'Introduction', duration: getSectionDuration(1), subsections: getNumberOfSubsections(1) },
  { id: 2, title: 'AMQP Concepts - Deep Dive', duration: getSectionDuration(2), subsections: getNumberOfSubsections(2) },
  { id: 3, title: 'Sample Code', duration: getSectionDuration(3), subsections: getNumberOfSubsections(3) },
  { id: 4, title: 'The Management Interface', duration: getSectionDuration(4), subsections: getNumberOfSubsections(4) },
  { id: 5, title: 'Running RabbitMQ', duration: getSectionDuration(5), subsections: getNumberOfSubsections(5) },
  { id: 11, title: 'RabbitMQ Streams', duration: getSectionDuration(11), subsections: getNumberOfSubsections(11) },
  { id: 6, title: 'Optimization and Tuning', duration: getSectionDuration(6), subsections: getNumberOfSubsections(6) },
  { id: 7, title: 'Plugins', duration: getSectionDuration(7), subsections: getNumberOfSubsections(7) },
  { id: 8, title: 'Protocols', duration: getSectionDuration(8), subsections: getNumberOfSubsections(8) },
  { id: 10, title: 'Best Practices', duration: getSectionDuration(10), subsections: getNumberOfSubsections(10) },
  { id: 9, title: 'Examination', duration: getSectionDuration(9), subsections: getNumberOfSubsections(9) }
]

function getNumberOfSubsections(sectionId) {
  let subsections = 0

  Subsections.forEach(subsection => {
    if (subsection.sectionId === sectionId) {
      subsections++
    }
  })

  return subsections
}

function getSubsectionDuration(subsectionId) {
  const subsection = Subsections.find(subsection => subsection.id === subsectionId)
  let seconds = subsection.duration
  return generateTimeString(seconds)
}

function getSectionDuration(sectionId) {
  let seconds = 0

  Subsections.forEach(subsection => {
    if (subsection.sectionId === sectionId) {
      seconds += subsection.duration
    }
  })

  return generateTimeString(seconds)
}

function getTotalDuration() {
  let seconds = 0

  Subsections.forEach(subsection => {
    seconds += subsection.duration
  })

  return generateTimeString(seconds)
}

function generateTimeString(seconds) {
  let time = new Date(seconds*1000).toISOString().slice(11, 19)

  let hours = time.slice(0, 2)
  if (hours.charAt(0) === '0') hours = hours.charAt(1)

  let minutes = time.slice(3, 5)
  if (minutes.charAt(0) === '0') minutes = minutes.charAt(1)

  let timeString = ''
  if (minutes === '0') {
    timeString = `${hours} hours`
  } else if (hours === '0') {
    timeString = `${minutes} min`
  } else {
    timeString = `${hours} hrs ${minutes} min`
  }

  return timeString
}

function getSubsectionIndex(subsection) {
  return Subsections.indexOf(subsection)
}

function getSectionIndex(section) {
  return Sections.indexOf(section)
}

export { Sections, Subsections, getSectionIndex, getSubsectionIndex, getTotalDuration, getSubsectionDuration }
