import React, { useState, createContext } from 'react'

const RatingContext = createContext()

const initialState = {
  show: false,
  userRating: null,
  currentRating: { id: 0, title: 'Select Rating', value: 0 },
  ratings: [
    { id: 0, title: 'Select Rating', value: 0 },
    { id: 1, title: 'Awful, not what I expected at all', value: 0.5 },
    { id: 2, title: 'Awful, not what I expected at all', value: 1 },
    { id: 3, title: 'Awful/Poor', value: 1.5 },
    { id: 4, title: 'Poor, pretty disappointed', value: 2 },
    { id: 5, title: 'Poor/Average', value: 2.5 },
    { id: 6, title: 'Average, could be better', value: 3 },
    { id: 7, title: 'Average/Good', value: 3.5 },
    { id: 8, title: 'Good, what I expected', value: 4 },
    { id: 9, title: 'Good/Amazing', value: 4.5 },
    { id: 10, title: 'Amazing, above expectations', value: 5 }]
}

const RatingContextProvider = (props) => {
  const [rating, setRating] = useState(initialState)
  return (
    <RatingContext.Provider value={[rating, setRating]}>
      {props.children}
    </RatingContext.Provider>
  )
}

export { RatingContext, RatingContextProvider }
