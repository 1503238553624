import React, { createContext, useState } from 'react'

const LectureMenuContext = createContext()

const LectureMenuContextProvider = (props) => {
  const [menu, setMenu] = useState(false)
  return (
    <LectureMenuContext.Provider value={[menu, setMenu]}>
      {props.children}
    </LectureMenuContext.Provider>
  )
}

export { LectureMenuContext, LectureMenuContextProvider }
