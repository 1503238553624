import React, { useReducer, createContext } from 'react'
import { UserReducer } from '../reducers/UserReducer'

const UserContext = createContext()

const initialState = {
  id: null,
  name: null,
  surname: null,
  waiting_for_exam_approval: false,
  exam_feedback: [],
  exam_passed: false,
  certificate: null,
  certificate_id: null
}

const UserContextProvider = (props) => {
  const [user, dispatchUser] = useReducer(UserReducer, initialState)
  return (
    <UserContext.Provider value={[user, dispatchUser]}>
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContext, UserContextProvider }
