import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import LecturePage from './components/LecturePage'
import LandingPage from './components/LandingPage'
import LoadCertificate from './components/LoadCertificate'
import PDFCertificate from './components/PDFCertificate'
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import './App.scss'

const analytics = Analytics({
  app: 'Training-tool',
  plugins: [
    googleAnalytics({
      measurementIds: ['UA-174199315-1']
    })
  ]
})

function App () {
  const [error, ] = useState(null)
  analytics.page()

  if (error) {
    return <div>Error: {error.message}</div>
  } else {
    return (
      <Switch>
        <Route path='/course/:courseId' component={LecturePage} />
        <Route path='/certificate/*/pdf' component={PDFCertificate} />
        <Route path='/certificate' component={LoadCertificate} />
        <Route path='*' component={LandingPage} />
      </Switch>
    )
  }
}

export default App
