import React, { useContext, useEffect } from 'react'
import Header from './Header'
import LectureMenu from './LectureMenu'
import VideoPlayer from './VideoPlayer'
import Notes from './Notes'
import Discussion from './Discussion'
import Quiz from './Quiz'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { SectionContext } from '../contexts/SectionContext'
import { CommentCount } from 'disqus-react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

function LecturePage () {
  const [sectionState, dispatchSection] = useContext(SectionContext)
  let { courseId } = useParams()

  useEffect(() => {
    dispatchSection({ type: 'SetSection', courseId: parseInt(courseId) })
  }, [courseId])

  function NotesDiscussionTab ({content}) {
    return (
      <Tab.Container role='tablist' defaultActiveKey='notes'>
        <Nav className='nav nav-tabs bg-light'>
          <Nav.Item>
            <Nav.Link className='nav-link' eventKey='notes' role='tab'>{content}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='nav-link' eventKey='discussion' role='tab'>Discussion<span className='badge badge-primary'><DisqusCommentCount /></span></Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className='tab-content'>
          <Tab.Pane className='tab-pane fade' eventKey='notes'>
            <Notes />
          </Tab.Pane>
          <Tab.Pane className='tab-pane fade' role='tabpanel' eventKey='discussion'>
            <Discussion />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    )
  }

  function DisqusCommentCount () {
    return (
      <CommentCount
        shortname='rabbitmq-training'
        config={{
          url: window.location.href,
          identifier: sectionState.subsection.id.toString(),
          title: sectionState.subsection.title
        }}
      />
    )
  }

  function Content () {
    const content = sectionState.subsection.content
    if (content === 'video') {
      return (
        <div>
          <VideoPlayer subsection={sectionState.subsection} />
          <NotesDiscussionTab content={'Notes'} />
        </div>
      )
    } else if (content === 'text' || content === 'practical_exercise') {
      return (
        <div className='note-box'>
          <NotesDiscussionTab content={'Notes'} />
        </div>
      )
    } else if (content === 'quiz' || content === 'theoretical_exam') {
      return (
        <div className='note-box'>
          <Quiz />
        </div>
      )
    } else if (content === 'practical_exam') {
      return (
        <div className='note-box'>
          <Notes />
        </div>
      )
    }
  }

  return (
    <div className='lecture'>
      <Helmet>
        <title> {sectionState.subsection.title} - RabbitMQ Training Course</title>
      </Helmet>
      <div id='wrapper'>
        <Header />
        <main id='main' role='main'>
          <div className='player-wrap'>
            <LectureMenu />
            <div className='player-content'>
              <Content />
            </div>
          </div>
        </main>
        {/* <RatingModal /> */}
      </div>
    </div>
  )
}

export default LecturePage
