import { Sections, Subsections } from '../components/Sections'

export const SectionReducer = (state, action) => {
  let section = state.section
  let subsection = state.subsection

  switch (action.type) {
    case 'SetSection':
      subsection = Subsections.find(subsection => subsection.id === action.courseId)
      if (subsection === undefined) {
        return { ...state, section, subsection: state.subsection }
      }
      section = Sections.find(section => section.id === subsection.sectionId)
      return { ...state, section, subsection }

    default:
      return state
  }
}
