import React, { useContext, useEffect } from 'react'
import { UserContext } from '../contexts/UserContext'
import { UserFromCertificateId } from './User'
import { useLocation } from 'react-router-dom'
import Certificate from './Certificate'

function PDFCertificate () {
  const [user, dispatchUser] = useContext(UserContext)
  const location = useLocation()

  useEffect(() => {
    if (!user.certificate) {
      const path = location.pathname.split('/')
      const certificate_id = path[path.length - 2]
      UserFromCertificateId(certificate_id).then(user => {
        if (user) {
          dispatchUser({ type: 'SetUser', user })
        }
      })
    }
  }, [location])

  if (user.certificate && user.name) {
    return (
      <Certificate/>
    )
  } else {
    return null
  }
}

export default PDFCertificate
