import React, { useContext } from 'react'
import { RatingContext } from '../contexts/RatingContext'
import { SaveRating } from './User'
import Modal from 'react-bootstrap/Modal'
import ReactStars from 'react-rating-stars-component'

function Rating () {
  const [rating, setRating] = useContext(RatingContext)

  if (!rating.userRating) {
    return (
      <div className='rating-wrap'>
        <button className='open-lightbox' onClick={() => setRating(state => ({ ...state, show: true }))}>
          <i className='fas fa-star'></i>
          <span className='text'>Leave a rating</span>
        </button>
      </div>
    )
  } else {
    return null
  }
}

function RatingModal () {
  const [rating, setRating] = useContext(RatingContext)

  function handleClick (value) {
    const currentRating = rating.ratings.find(rating => rating.value === value)
    setRating(state => ({ ...state, currentRating }))
    SaveRating(currentRating.value)
    setRating(state => ({ ...state, userRating: currentRating.value }))
  }

  return (
    <Modal class='popup-holder' dialogClassName='rating-lightbox' show={rating.show} onHide={() => setRating(state => ({ ...state, show: false }))}>
      <Modal.Header closeButton />
      <Modal.Body>
        <h1>How would you rate this course?</h1>
        <div className='rating-box'>
          <span className='text'>{rating.currentRating.title}</span>
          <ReactStars
            className='rating-form'
            count={5}
            onChange={handleClick}
            size={60}
            half
            value={rating.currentRating.value}
            emptyIcon={<i className='far fa-star' />}
            halfIcon={<i className='fa fa-star-half-alt' />}
            fullIcon={<i className='fa fa-star' />}
            color2='#f60'
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { Rating, RatingModal }
