import React from 'react'
import HeaderFront from './HeaderFront'
import Main from './Main'
import SignIn from './SignIn'
import Footer from './Footer'

function LandingPage () {
  return (
    <div className='home-page'>
      <div id='wrapper'>
        <HeaderFront />
        <Main />
        <SignIn />
        <Footer />
      </div>
    </div>
  )
}
export default LandingPage
