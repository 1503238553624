import React, { useState, useContext, useEffect } from 'react'
import { SignInContext } from '../contexts/SignInContext'
import { getTotalDuration, getSubsectionDuration, Sections, Subsections, getSubsectionIndex } from './Sections'
import { AverageRating } from './User'
import { Helmet } from 'react-helmet-async'
import Accordion from 'react-bootstrap/Accordion'
import Modal from 'react-bootstrap/Modal'
import VideoPlayer from './VideoPlayer'

function Main () {
  const [, setSignIn] = useContext(SignInContext)
  const [, setAverage] = useState(0)
  const [sectionKey, setSectionKey] = useState(1)
  const [preview, setPreview] = useState(null)
  let lectures = 0

  Sections.forEach(section => {
    lectures += section.subsections
  })

  useEffect(() => {
    AverageRating().then(rating => setAverage(rating))
    // GetUsers().then(count => setUsers(count))
  }, [])

  function sectionClick (section) {
    if (sectionKey === 0) {
      setSectionKey(section.id)
    } else if (section.id === sectionKey) {
      setSectionKey(0)
    } else {
      setSectionKey(section.id)
    }
  }

  function returnMediaTypeIcon (sectionType) {
    let mediaTypeIcon = ''

    if (sectionType === 'video') {
      mediaTypeIcon = 'video'
    } else if (sectionType === 'quiz') {
      mediaTypeIcon = 'badge-check'
    } else if (sectionType === 'theoretical_exam' || sectionType === 'practical_exam') {
      mediaTypeIcon = 'graduation-cap'
    } else if (sectionType === 'practical_exercise') {
      mediaTypeIcon = 'laptop-code'
    } else {
      mediaTypeIcon = 'align-left'
    }

    return mediaTypeIcon
  }

  function Subsection () {
    return (
      <ul className='media-list'>
        {Subsections.map((subsection) => {
          if (sectionKey === subsection.sectionId) {
            return (
              <li key={subsection.id}>
                <div className='left-holder'>
                  <div className='media-type'><i className={`far fa-${returnMediaTypeIcon(subsection.content)}`}></i></div>
                  <h4 className='name-media'>{subsection.title}</h4>
                </div>
                <div className='right-holder'>
                  {getSubsectionIndex(subsection) <= 1 &&
                    <button className='link' onClick={(() => setPreview(subsection))}>Preview</button>
                  }
                  <span className='time'>{getSubsectionDuration(subsection.id)}</span>
                </div>
              </li>
            )
          }
        })}
      </ul>
    )
  }

  function Preview () {
    return (
      <Modal show={preview} onHide={() => setPreview(false)}>
        <Modal.Body>
          <VideoPlayer subsection={preview} />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <main id='main' role='main'>
      <Helmet>
        <title>Free RabbitMQ Training Course</title>
      </Helmet>
      <div className='top-inform-section'>
        <div className='container'>
          <div className='row flex-md-row-reverse'>
            <div className='col-md-6'>
              <div className='open-lightbox-frame'>
                <img src={process.env.PUBLIC_URL + '/images/img-video.png'} alt='Screenshot of the user interface of the RabbitMQ training tool' />
              </div>
            </div>
            <div className='col-md-6'>
              <h1>The Best Online Training Tool for RabbitMQ</h1>
              <p>Learn everything you need to know to master RabbitMQ. Learn from the experts behind CloudAMQP - the world's largest provider of RabbitMQ clusters. Go all the way from learning the basics, via tips and tricks from a realistic case study, to master best practices.</p><button className='btn btn-warning' onClick={() => setSignIn(true)}>Start free <i className='far fa-long-arrow-right' /></button>
            </div>
          </div>
        </div>
      </div>
      <div className='advantages-section'>
        <div className='container'>
          <ul className='advantages-list'>
            <li><i className='far fa-comment' />English</li>
            <li><i className='far fa-hourglass-half' />{getTotalDuration()}</li>
            <li><i className='far fa-book' />{lectures} lectures</li>
          </ul>
        </div>
      </div>
      <div className='inform-section'>
        <div className='container'>
          <h2>What You'll Learn</h2>
          <ul className='inform-list'>
            <li>How to build truly scalable applications with RabbitMQ </li>
            <li>From how to send your first message all the way to running it in production</li>
            <li>Benefits of RabbitMQ, AMQP, and a Message Queue architecture</li>
            <li>Installing and configuring RabbitMQ and plugins</li>
            <li>Application scalability, resource efficiency, and user reliability</li>
            <li>Real use cases of RabbitMQ including  tips from leading experts</li>
          </ul>
        </div>
      </div>
      <Preview />
      <div className='course-section'>
        <div className='container'>
          <div className='head'>
            <h2>Course Content</h2>
            <ul className='info-list'>
              <li>{lectures} lectures</li>
              <li>{getTotalDuration()}</li>
            </ul>
          </div>
          <Accordion id='accordionCourse' activeKey={sectionKey}>
            {Sections.map((section) => (
              <div className='course-item' key={section.id}>
                <div role='tabpanel'>
                  <Accordion.Toggle className={section.id === sectionKey ? 'open-course' : 'open-course collapsed'} as='div' eventKey={section.id} onClick={() => sectionClick(section)}>
                    <h3 className='name'> {section.title}</h3><span className='time'>{section.duration}</span>
                  </Accordion.Toggle>
                </div>
                <Accordion.Collapse eventKey={section.id} role='tabpanel'>
                  <div className='course-item-body'>
                    <Subsection />
                  </div>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
      <div className='training-section'>
        <div className='container'>
          <h2>RabbitMQ Training - How It Works</h2>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='training-block'>
                <div className='picture-holder'><img src={process.env.PUBLIC_URL + '/images/icon-rabbitmq-certificate.png'} alt='Illustrated RabbitMQ training certificate' /></div>
                <h3>RabbitMQ Certified - For Free</h3>
                <p>Complete the training to receive a meritorious certificate that proves your newfound skills. </p>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='training-block'>
                <div className='picture-holder'><img src={process.env.PUBLIC_URL + '/images/icon-learn-at-your-pace.png'} alt='Illustrated calendar' /></div>
                <h3>Learn at Your Own Pace</h3>
                <p>The training tool will remember your last position so you can pick up where you left off.</p>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='training-block'>
                <div className='picture-holder'><img src={process.env.PUBLIC_URL + '/images/icon-forum.png'} alt='Illustrated speech bubbles and text' /></div>
                <h3>Ask Questions</h3>
                <p>Use discussion boards to ask questions and find answers from participants and teachers.</p>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='training-block'>
                <div className='picture-holder'><img src={process.env.PUBLIC_URL + '/images/icon-track-progress.png'} alt='Illustrated progress tracking on a laptop' /></div>
                <h3>Track Your Progress</h3>
                <p>Completed chapters will automatically be marked as complete to  calculate what is left.</p>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='training-block'>
                <div className='picture-holder'><img src={process.env.PUBLIC_URL + '/images/icon-take-quiz.png'} alt='Illustrated checked list, a test of knowledge' /></div>
                <h3>Test Your Knowledge</h3>
                <p>Take a quiz after each chapter to enforce and track your learning curve.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='team-section'>
        <div className='container'>
          <h2>Learn From World-leading Experts</h2>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-4 text-md-left'>
              <div className='picture-holder'><img src={process.env.PUBLIC_URL + '/images/cloudamqp-team.png'} alt='The CloudAMQP team' /></div>
            </div>
            <div className='col-12 col-md-8'>
              <h3>Meet Your Teachers - The CloudAMQP Team</h3>
              <p>With over 50,000 running instances CloudAMQP is the leading hosting provider of RabbitMQ in the world. A lot of our clients like Mozilla, Docker, and Salesforce have stayed with us since the beginning. </p>
              <p>CloudAMQP offers RabbitMQ as a Service so you can be up and running in minutes. We automate every part of the setup, including running and scaling of RabbitMQ clusters.</p>
              <p className='lead'><a href='https://cloudamqp.com' target='_blank' rel='noopener noreferrer'>Visit CloudAMQP website to learn more</a></p>
            </div>
          </div>
        </div>
      </div>
      <div className='jumbotron-wrap'>
        <div className='container'>
          <div className='jumbotron'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6'>
                <h3>Join Thousands of People Learning RabbitMQ</h3>
              </div>
              <div className='col-12 col-md-6 d-md-flex justify-content-md-end text-center'><button className='btn btn-warning btn-lg btn-on-dark' onClick={() => setSignIn(true)}>Start free</button></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Main
