const questions = [
  {
    question: 'Which language is RabbitMQ written in?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Python', 
      'Java', 
      'Scala', 
      'Erlang'
    ],
    correctAnswer: '4',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1',
  },
  {
    question: 'Feature flags is a mechanism new to RabbitMQ version 3.8. What are the feature flags?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'These flags define a RabbitMQ node\'s ability to become a part of a cluster, by controlling which features are considered enabled or available on all cluster nodes.',
      'The feature flag is "true" when one node has joined another node with federation enabled.',
      'Feature flags is a  mechanism telling the consumer that specific plugins are enabled on the broker.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  // {
  //   question: 'Have you managed to set up a RabbitMQ server (via CloudAMQP or installed locally)?',
  //   questionType: 'text',
  //   answerSelectionType: 'single',
  //   answers: [
  //     'Yes',
  //     'No'
  //   ],
  //   correctAnswer: '1',
  //   messageForCorrectAnswer: 'Correct answer. Good job!',
  //   messageForIncorrectAnswer: 'Incorrect answer.',
  //   explanation: '',
  //   point: '1'
  // },
  {
    question: 'Classic mirrored queues have been a feature used for high availability in RabbitMQ. Should this still be the default choice for a replicated queue type?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about Quorum queues?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'Quorum queues are available as of RabbitMQ 3.8.0.',
      'All communication is routed to the queue leader.',
      'Quorum queues may confirm messages too early and lose data.',
      'In Quorum queues the leader and replication are consensus-driven, which means they agree on the state of the queue and its contents.',
      'Quorum queues were replaced by Mirrored queues (which is the preferred queue type for high availability).',
      'Quorum queues will only confirm when the majority of its nodes are available.',
      'Quorum queues only work with an even number of nodes.'
    ],
    correctAnswer: [1, 2, 4, 6],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What are poison messages?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Invalid messages that can\'t be sent to the broker due to the content.',
      'Messages that are never consumed completely or positively acknowledged.',
      'Messages that are too large for the broker to handle.'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is the x-delivery-count header used for?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'It tracks and displays the number of unsuccessful delivery attempts.',
      'It decides the number of messages sent in one batch.',
      'It routes messages to a randomized queue.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about RabbitMQ Streams?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'RabbitMQ streams is a new feature available from RabbitMQ version 3.9',
      'RabbitMQ streams let you stream data through the UDP protocol.',
      'Messages will stay in queues until they are expired with retention policies.',
      'RabbitMQ streams are only available when using mirrored queues.',
      'Messages can be consumed multiple times.'
    ],
    correctAnswer: [1, 3, 5],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about Lazy queues?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'Lazy queues store messages to disk automatically in order to minimize RAM usage.',
      'Lazy queues only store messages in RAM.',
      'Lazy queues are a policy.',
      'Lazy queues are enabled from RabbitMQ version 3.6.',
      'Lazy queues send messages at a slow rate since messages are also stored in a specific database called indigi.'
    ],
    correctAnswer: [1, 3, 4],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'An example of an argument for messages and queues is “TTL”. What does this mean?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Time to lockdown',
      'True time life',
      'Time to live'
    ],
    correctAnswer: '3',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'An example of a queue property is “durable”. What does this mean?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'If a queue is durable, the queue remains when a server restarts.',
      'If a queue is durable, all messages in the queue are also by default persistent messages.',
      'A durable queue is always a lazy queue.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What does the exchange property type “internal” initiate?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Messages too large to handle get redirected to exchanges with this property setting.',
      'The exchange can not be used directly by publishers.',
      'Messages sent to an exchange with this property setting get automatically encrypted.'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What does this image illustrate?',
    questionType: 'text',
    questionPic: '/images/image5-13.png',
    answerSelectionType: 'multiple',
    answers: [
      'Illustration of a message published with TTL expiration set to 36000000 milliseconds.',
      'Illustration of a message published with a payload saying “Hello world!”',
      'Illustration of a persistent message published to a test queue.'
    ],
    correctAnswer: [1, 2],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about policies?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'Policies are created per vhost.',
      'All policies in a system must be uniformly configured.',
      'As soon as a policy is created it will be applied to the matching queues and/or exchanges and its arguments will be amended to the definitions.',
      'Policies can only be set from the terminal using rabbitmqctl.',
      'Policies can be changed at any time, and changes will affect all matching queues and exchanges.',
      'Policies cannot be changed after creating a queue.',
      'Policies can be advantageously used to apply queue or exchange arguments to more than one created queue/exchange.'
    ],
    correctAnswer: [1, 3, 5, 7],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What are the different ways to acknowledge message delivery?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'Durable Acknowledgement',
      'Manual Acknowledgement',
      'Automatic Acknowledgement'
    ],
    correctAnswer: [2, 3],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'When using quorum queues, is a message removed from the queue once it has been acknowledged?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What are Negative Acknowledgments?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'A way for the consumer to tell the broker that the consumer could not handle the message.',
      'A way for the broker to tell the consumer that it has received an invalid message.',
      'Are sent from the publisher to the consumer when the publisher is not able to route a message.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  }
].sort(() => Math.random() - 0.5)

export const Quiz = {
  quizTitle: 'Quiz: Running RabbitMQ',
  quizSynopsis: 'It\'s time to review what you\'ve learned about advanced features and techniques in RabbitMQ. This is just a practice quiz and the score does not count towards your final examination. You should simply see it as a good preparation for the theoretical exam at the end of this course. For the quiz to be marked as complete you need to score 85%. You can retake the quiz as many times as you need. Good Luck!',
  nrOfQuestions: '6',
  questions: questions,
  appLocale: {
    landingHeaderText: '<questionLength> questions | No time limit',
    startQuizBtn: 'Start quiz',
    resultPageHeaderText: 'You finished with a score of <correctIndexLength> out of <questionLength>.'
  }
}
