import React, { useContext } from 'react'
import { SignInContext } from '../contexts/SignInContext'

function HeaderFront () {
  const [, setSignIn] = useContext(SignInContext)

  return (
    <header id='header'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container'>
          <div className='brand-wrap'>
            <div className='navbar-brand'>
              <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt='RabbitMQ Training logo' width='29' height='41' />
            </div>
            <span className='slogan d-none d-lg-block'>Free RabbitMQ Training Course - <a href='https://www.cloudamqp.com' target='_blank' rel='noopener noreferrer'>by CloudAMQP</a></span>
          </div>
          {/* <a className='home-hidden open-filter-bar collapsed' data-toggle='collapse' href='#mobileDrop' role='button' aria-expanded='false'>
            <span />
            <span />
            <span />
          </a> */}
          <button className='btn btn-warning home-visible' onClick={() => setSignIn(true)}>Start free</button>
        </div>
      </nav>
    </header>
  )
}

export default HeaderFront
