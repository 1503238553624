import React, { useState, useContext } from 'react'
import { SectionContext } from '../contexts/SectionContext'
import { ProgressContext } from '../contexts/ProgressContext'
import { UserContext } from '../contexts/UserContext'
import ContentLoader from './ContentLoader'
import { NextSection } from './SectionChange'
import { useHistory } from 'react-router-dom'

function Quiz () {
  const [sectionState, ] = useContext(SectionContext)
  const [progress, dispatchProgress] = useContext(ProgressContext)
  const [user, ] = useContext(UserContext)
  const [quizDone, setQuizDone] = useState(false)
  const [passed, setPassed] = useState(false)
  const history = useHistory()

  function ResultMessage () {
    let messageClasses = '';
    let message = '';

    if (passed) {
      messageClasses = 'alert passed';
      message = 'You passed the quiz. Good job! Looks like you\'ve learned a lot!'

    } else {
      messageClasses = 'alert not-passed';
      message = 'You didn\'t pass the quiz, you need to score at least 85%. Feel free to try again!'

      if (sectionState.subsection.content === 'theoretical_exam') {
        message = 'Unfortunately you did not pass the exam, you need to score at least 85%. Restart the exam whenever you feel ready to try again.'
      }
    }

    return (
      <div className={messageClasses}>{message}</div>
    )
  }

  function goToNextSection () {
    NextSection(sectionState.subsection, user, progress, dispatchProgress, passed, history)
    setQuizDone(false)
    setPassed(false)
  }

  function NextSectionButton () {
    return (
      <button className='btn btn-warning' onClick={() => goToNextSection()}>Next section</button>
    )
  }

  function RestartButton () {
    if (sectionState.subsection.content !== 'theoretical_exam') {
      return (
        <button className='btn btn-warning' onClick={() => window.location.reload(true)}>Retake quiz</button>
      )
    } else {
      return (
        <button className='btn btn-warning' onClick={() => window.location.reload(true)}>Restart exam</button>
      )
    }
  }

  return (
    <div className='row'>
      <div className='col-12 markdown-body'>
        <div className='quiz-content'>
          <ContentLoader done={(done) => setQuizDone(done)} passed={(passed) => setPassed(passed)} />
          {quizDone && (
            <>
              <ResultMessage />
              {passed ? <NextSectionButton /> : <RestartButton />}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Quiz
