const questions = [
  {
    question: 'Link the type of exchange to its specification',
    questionType: 'text',
    questionPic: '/images/image2-1.png',
    answerSelectionType: 'single',
    answers: [
      'a→4, b→1, c→2, d→3',
      'a→3, b→4, c→2, d→1',
      'a→2, b→1, c→3, d→4',
      'a→1, b→2, c→4, d→3',
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Which version of AMQP is the default version of the AMQP protocol in RabbitMQ?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      '0.9.1',
      '1.0',
      '0.8'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Asynchronous communication and decoupling are mentioned multiple times as they are important concepts. What primary strengths are gained from this type of communication?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'A fault-tolerant, stable system in which a failure of one single service might not cause overall problems or even make the entire application go down.',
      'A high-performance system.',
      'A secure system that prevents corruption of data.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Which of the following statements are correct?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'AMQP is an internet standard communication protocol for electronic mail transmission',
      'AMQP is a binary protocol'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about RabbitMQ connections?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'IP4',
      'IP6',
      'TLS',
      'Short-lived'
    ],
    correctAnswer: [1, 2, 3 ],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'A message is considered successfully delivered once the message has been acknowledged. A message can be acknowledged in different ways. Which statements are true?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'The message can be acknowledged at the same time as it is sent out from the broker.',
      'A message can be acknowledged once it is written to a TCP socket.',
      'A message can be considered successfully delivered when the message is explicitly nack:ed by the consumer.',
      'A message can be considered successfully delivered when an explicit acknowledgment is received by the broker, from the consumer.'
    ],
    correctAnswer: [1, 2, 4],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Pair the queue setting with the right outcome:',
    questionType: 'text',
    questionPic: '/images/image2-7.png',
    answerSelectionType: 'single',
    answers: [
      'a→3, b→2, c→1',
      'a→1, b→2, c→3',
      'a→2, b→1, c→3'
    ],
    correctAnswer: '3',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Scaling the message flow through RabbitMQ can be done primarily through which method:',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Vertically, by adding more consumers.',
      'Horizontal, by adding more resources to the RabbitMQ broker.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is a Publisher Confirm?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'RabbtiMQ can acknowledge that a message has been received, with the use of publisher confirms.',
      'When the consumer acknowledges a received message by a publisher confirmation.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What benefits can be gained from enabling Consumer Acknowledgments?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'It can help to secure your service from unnoticed message loss and/or protect services or applications that can not afford to lose any messages.',
      'It will make your service faster.',
      'It will ensure that messages stay in the broker.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'When operating in one vhost, is it possible to reach users, exchanges, and queues within another vhost?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'No. When using basic settings, this is not possible since users, policies, etc. are unique to each vhost.',
      'Yes.',
      'Only exchanges are reachable between vhosts.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
].sort(() => Math.random() - 0.5)

export const Quiz = {
  quizTitle: 'Quiz: AMQP Concepts - Deep Dive',
  quizSynopsis: 'It\'s time to review what you\'ve learned about different AMQP concepts. This is just a practice quiz and the score does not count towards your final examination. You should simply see it as a good preparation for the theoretical exam at the end of this course. For the quiz to be marked as complete you need to score 85%. You can retake the quiz as many times as you need. Good Luck!',
  nrOfQuestions: '6',
  questions: questions,
  appLocale: {
    landingHeaderText: '<questionLength> questions | No time limit',
    startQuizBtn: 'Start quiz',
    resultPageHeaderText: 'You finished with a score of <correctIndexLength> out of <questionLength>.'
  }
}
