import React, { useContext } from 'react'
import { SectionContext } from '../contexts/SectionContext'
import ContentLoader from './ContentLoader'
import { ProgressContext } from '../contexts/ProgressContext'
import { NextSection } from './SectionChange'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../contexts/UserContext'

function Notes () {
  const [sectionState, ] = useContext(SectionContext)
  const [progress, dispatchProgress] = useContext(ProgressContext)
  const [user, ] = useContext(UserContext)
  const history = useHistory()

  function NextSectionButton () {
    let buttonText = 'Next Lecture'
    if (sectionState.subsection.content === 'practical_exercise') buttonText = 'Next section'

    return (
      <button className='btn btn-warning mt-4' onClick={() => NextSection(sectionState.subsection, user, progress, dispatchProgress, true, history)}>{buttonText}</button>
    )
  }

  return (
    <div className='row'>
      <div className='col-12 markdown-body'>
        <div className='text-content'>
          <ContentLoader />
          {(sectionState.subsection.content === 'text' || sectionState.subsection.content === 'practical_exercise') && (
            <NextSectionButton />
          )}
        </div>
      </div>
    </div>
  )
}

export default Notes
