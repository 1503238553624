import React, { createContext, useReducer } from 'react'
import { SectionReducer } from '../reducers/SectionReducer'
import { Sections, Subsections } from '../components/Sections'

const SectionContext = createContext()

const initialState = {
  section: Sections[0],
  subsection: Subsections[0]
}

const SectionContextProvider = (props) => {
  const [section, dispatchSection] = useReducer(SectionReducer, initialState)
  return (
    <SectionContext.Provider value={[section, dispatchSection]}>
      {props.children}
    </SectionContext.Provider>
  )
}

export { SectionContext, SectionContextProvider }
