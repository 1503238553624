import React, { useContext } from 'react'
import ReactPlayer from 'react-player/vimeo'
import { ProgressContext } from '../contexts/ProgressContext'
import { UserContext } from '../contexts/UserContext'
import { NextSection } from './SectionChange'
import { useHistory } from 'react-router-dom'

function VideoPlayer ({ subsection }) {
  const [progress, dispatchProgress] = useContext(ProgressContext)
  const [user, ] = useContext(UserContext)
  const history = useHistory()

  return (
    <div className='player-box'>
      <ReactPlayer className='react-player' url={subsection.video} controls height='100%' width='100%' onEnded={() => NextSection(subsection, user, progress, dispatchProgress, true, history)} />
    </div>
  )
}

export default VideoPlayer
