import React, { useContext, useEffect, useState } from 'react'
import Subsection from './Subsection'
import { SectionContext } from '../contexts/SectionContext'
import { UserContext } from '../contexts/UserContext'
import { ProgressContext } from '../contexts/ProgressContext'
import { LectureMenuContext } from '../contexts/LectureMenuContext'
import { RatingContext } from '../contexts/RatingContext'
import Accordion from 'react-bootstrap/Accordion'
import Profile from './Profile'
import { Rating } from './Rating'
import Cookies from 'universal-cookie'
import { Sections, getSectionIndex } from './Sections'
import { useHistory } from 'react-router-dom'
import { User } from './User'


function LectureMenu () {
  const [sectionState, ] = useContext(SectionContext)
  const [user, dispatchUser] = useContext(UserContext)
  const [progress, dispatchProgress] = useContext(ProgressContext)
  const [, setRating] = useContext(RatingContext)
  const [menu, ] = useContext(LectureMenuContext)
  const [sectionKey, setSectionKey] = useState(1)
  const cookies = new Cookies()
  const history = useHistory()

  useEffect(() => {
    const userCookie = cookies.get('user')
    if (userCookie) {
      if (!user.id) {
        User(userCookie.email, null).then(user => {
          if (user && user.email) {
            dispatchUser({ type: 'SetUser', user })
            if (user.rating) {
              setRating(state => ({ ...state, userRating: user.rating }))
            }
            if (user.progress) {
              dispatchProgress({ type: 'SetUserProgress', user })
            }
          }
        })
      }
    } else {
      history.push('/')
    }
  }, [cookies, history])

  useEffect(() => {
    setSectionKey(sectionState.section.id)
  }, [sectionState])

  function sectionClick (section) {
    if (sectionKey === 0) {
      setSectionKey(section.id)
    } else if (section.id === sectionKey) {
      setSectionKey(0)
    } else {
      setSectionKey(section.id)
    }
  }

  return (
    <div className={menu ? 'filter-bar collapse show' : 'filter-bar collapse'} id='mobileDrop' role='tablist'>
      <div className='editor-actions-menu d-md-none'>
        <Rating />
        <Profile />
      </div>
      <Accordion id='accordionFilterBar' activeKey={sectionKey}>
        {Sections.map((section) => (
          <div className='filter-item' key={section.id}>
            <div role='tab'>
              <Accordion.Toggle className={section.id === sectionKey ? 'open-filter' : 'open-filter collapsed'} as='div' eventKey={section.id} onClick={() => sectionClick(section)}>
                <span className='item'>Section {getSectionIndex(section) + 1}</span><strong className='title'>{section.title}</strong>
                <ul className='info-list'>
                  <li>{progress.sectionProgress.length === 0 ? 0 : progress.sectionProgress[section.id - 1]}/{section.subsections}</li>
                  <li>{section.duration}</li>
                </ul>
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse className='collapse' eventKey={section.id} role='tabpanel'>
              <div className='filter-item-body'>
                <Subsection sectionId={sectionKey} />
              </div>
            </Accordion.Collapse>
          </div>
        ))}
      </Accordion>
    </div>
  )
}

export default LectureMenu
