
function User (email, consentToTrack) {
  return fetch('/user', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, consentToTrack })
  })
    .then(res => res.json())
    .catch(err => {
      console.error('Error fetching user', err)
    })
}

function UserFromCertificateId (certificateId) {
  return fetch('/user/' + certificateId, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.error('Error fetching user', err)
    })
}

function GetEmail (id) {
  return fetch('/user/email/' + id, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.error('Error fetching email', err)
    })
}

function SaveName (name) {
  return fetch('/user/name', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ name })
  })
    .catch(err => {
      console.error('Error fetching name', err)
    })
}

function GetUsers () {
  return fetch('/users', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.error('Error fetching number of users', err)
    })
}

function SaveProgress (id) {
  return fetch('/user/progress', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id })
  })
    .catch(err => {
      console.error('Error saving progress', err)
    })
}

function DeleteProgress (id) {
  return fetch('/user/progress', {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id })
  })
    .catch(err => {
      console.error('Error deleting progress', err)
    })
}

function SaveRating (value) {
  return fetch('/user/rating', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ value })
  })
    .catch(err => {
      console.error('Error saving rating', err)
    })
}

function AverageRating () {
  return fetch('/user/rating', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.error('Error fetching average rating', err)
    })
}

function SetPracticalExam (practical_exam_done) {
  return fetch('/user/practical_exam', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ practical_exam_done })
  })
    .catch(err => {
      console.error('Error submitting practical exam', err)
    })
}

function ExamApproval () {
  return fetch('/user/exam_approval', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .catch(err => {
      console.error('Error fetching email', err)
    })
}


function SendCertificate () {
  return fetch('/certificate', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .catch(err => {
      console.error('Error sending certificate', err)
    })
}

function GeneratePDF(certificate_id) {
    return fetch('/pdf/' + certificate_id, {
    method: 'GET',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/pdf' }
  })
    .then(res => res.arrayBuffer())
    .catch(err => {
      console.error('Error generating certificate', err)
    })
}

function SignOut () {
  return fetch('/user/signout', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .catch(err => {
      console.error('Error signing out', err)
    })
}

export { User, UserFromCertificateId, GetEmail, SaveName, GetUsers, SaveProgress, DeleteProgress, SaveRating, AverageRating, SetPracticalExam, ExamApproval, SendCertificate, GeneratePDF, SignOut }
