import { Subsections, getSubsectionIndex } from './Sections'

function NextSection (subsection, user, progress, dispatchProgress, passed, history) {
  dispatchProgress({ type: 'AddProgress', subsection })
  const nextSubsection = Subsections[getSubsectionIndex(subsection) + 1]
  if (nextSubsection !== undefined) {
    if (disable_practical_exam(user, progress, nextSubsection)) {
      return
    } else {
      history.push('/course/' + nextSubsection.id)
      window.scrollTo(0, 0)
    }
  }
}

function SubsectionClick (subsectionState, subsection, user, progress, history) {
  if (disable_practical_exam(user, progress, subsection)) {
    return
  } else if (subsectionState !== subsection) {
      history.push('/course/' + subsection.id)

      const content = document.querySelector('.player-content')
      content.scrollIntoView(true);
  }
}

function disable_practical_exam (user, progress, subsection) {
  return subsection.content === 'practical_exam' && !progress.progress.includes(57) && !user.exam_passed
}

export { NextSection, SubsectionClick }
