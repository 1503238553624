import React, { useState, createContext } from 'react'

const SignInContext = createContext()

const SignInContextProvider = (props) => {
  const [signIn, setSignIn] = useState(false)
  return (
    <SignInContext.Provider value={[signIn, setSignIn]}>
      {props.children}
    </SignInContext.Provider>
  )
}

export { SignInContext, SignInContextProvider }
