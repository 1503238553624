import { SaveName, SetPracticalExam } from '../components/User'

export const UserReducer = (state, action) => {
  let user = null
  let name = null
  let surname = null
  let certificate = null
  let certificate_id = null

  switch (action.type) {
    case 'SetUser':
      user = action.user
      if (user.name) {
        name = user.name[0]
        surname = user.name[1]
      }
      if (user.certificate) {
        certificate = user.certificate
        certificate_id = user.certificate_id
      }
      return { ...state, id: user.id, name, surname, waiting_for_exam_approval: user.waiting_for_exam_approval, exam_feedback: user.exam_feedback, exam_passed: user.exam_passed, certificate, certificate_id }

    case 'AddName':
      if (action.name !== undefined) {
        name = action.name.toLowerCase().split(' ')
        surname = action.surname.toLowerCase().split(' ')
        name.forEach(function(word, index) {
          name[index] = word[0].toUpperCase() + word.substr(1)
        })
        surname.forEach(function(word, index) {
          surname[index] = word[0].toUpperCase() + word.substr(1)
        })
        name = name.join(' ')
        surname = surname.join(' ')
        const full_name = [name, surname]
        SaveName(full_name)
      }
      return { ...state, name, surname }

    case 'SetPracticalExam':
      let done = action.done
      SetPracticalExam(done)
      return { ...state, waiting_for_exam_approval: done }

    default:
      return state
  }
}
