const questions = [
  {
    question: 'Message queuing allows applications to communicate by sending messages to each other. Which statement describes “message queuing” the best?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'The message queue provides temporary message storage when the destination program is busy or not connected.',
      'A message queue rewrites messages to fit the receiving application\'s standard so that no messages are lost.',
      'A message queue acts like a mirror for the messages sent from one application to another.',
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What are the basics of a message queuing system?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'queue, binary data, zip-file',
      'Sender, receiver, queue, message',
      'Storage'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'The service sending messages can be called by different names. The most common term for describing sending applications is:',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Maker, creator',
      'Producer, Publisher',
      'Promoter, Manager'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Are messages added to the end of the Queue?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Can you publish messages via the RabbitMQ management interface?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Is the queue located inside the message broker?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'When using a message queue; the communication used is',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Asynchronous',
      'Synchronous'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Microservices are a group of components that communicate through a centralized service, like a Message Queue?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  // {
  //   question: 'Different services in a microservice architecture can be;',
  //   questionType: 'text',
  //   answerSelectionType: 'multiple',
  //   answers: [
  //     'Written in different languages',
  //     'Developed by different teams',
  //     'Independently evolved'
  //   ],
  //   correctAnswer: [1, 2, 3],
  //   messageForCorrectAnswer: 'Correct answer. Good job!',
  //   messageForIncorrectAnswer: 'Incorrect answer.',
  //   explanation: '',
  //   point: '1'
  // },
  {
    question: 'Are messages always sent in the file format JSON?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about a message that has entered a queue in the broker?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'It can be re-delivered multiple times until the consumer can process it successfully and will stay in the queue until the consuming service is up and running (even if it suffers from a broker restart or other failures).',
      'The message is safe within the Broker and suffers a minimal risk of getting lost since all services are unaware and unbound of each other and the impact of a failure of one service won\'t affect the others or messages in the queue.',
      'Each component and its associated components must be present in order for code to be executed or compiled.',
    ],
    correctAnswer: [1, 2],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What are the potential benefits of having a message queue as part of the architecture?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'Makes it easy to decouple your application.',
      'Enables permanent storage of your data, almost like a database.',
      'Reliable message transfer.',
      'Offloads your data store.',
      'Resilience.',
      'Enables asynchronous communication.'
    ],
    correctAnswer: [1, 3, 4, 5, 6],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Does a message in a queue need to be processed immediately?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is a Consumer?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'The Consumer is a customer buying messages from the message broker.',
      'The Consumer is supposed to take action once it receives the message, whether it is a task or information that needs to be processed.',
      'The Consumer is the service receiving message from the broker.',
      'The consumer is a hosted service provider at CloudAMQP.'
    ],
    correctAnswer: [2,3],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Can a message broker include many queues?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes, the message broker itself can have one or many queues.',
      'No, there can only exist one queue per message broker.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is a broker?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'A Broker is a software that acts as a middleman to the services, receiving messages from one service (Producers) and handing them over to others (Consumers) to complete the task.',
      'A Broker is a database for large blocks of data.',
      'A Broker is a data transformation service.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  // {
  //   question: 'What are examples of messages?',
  //   questionType: 'text',
  //   answerSelectionType: 'single',
  //   answers: [
  //     'Events, tasks, or plain messages are sent from one application to another.'
  //   ],
  //   correctAnswer: '1',
  //   messageForCorrectAnswer: 'Correct answer. Good job!',
  //   messageForIncorrectAnswer: 'Incorrect answer.',
  //   explanation: '',
  //   point: '1'
  // },
  {
    question: 'What is CloudAMQP?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'The largest hosting provider of RabbitMQ.',
      'A database hosting provider from AWS.',
      'A system configuration option in RabbitMQ.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Retailers and media-streaming services are frequently scaling their systems up and down during peak hours. It is easy to add more consumers to message queuing systems. Why would you do that?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'To keep the queues as short as possible.',
      'To keep the system flexible and to avoid building an over-dimensional system.',
      'To avoid unnecessary costs.',
      'To have something to do for the developers working with the application.'
    ],
    correctAnswer: [1, 2, 3],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about RabbitMQ?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'RabbitMQ is a closed source/hidden source message broker.',
      'RabbitMQ is the most deployed open-source message queue in the world.',
      'RabbitMQ is a heavyweight message broker.',
      'Queues in RabbitMQ are FIFO (first in, first out).',
      'RabbitMQ provides a lightweight buffer that temporarily stores messages.',
      'RabbitMQ is a mature, solid message broker.'
    ],
    correctAnswer: [2, 4, 5, 6],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Which programming language is a RabbitMQ server written in?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Crystal',
      'Java',
      'Erlang',
      'C#'
    ],
    correctAnswer: '3',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Does RabbitMQ support many or just a few program languages?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Only supported by Java and the Spring framework',
      'RabbitMQ can only be used by websites built on WordPress.',
      'Many programming languages are supported.'
    ],
    correctAnswer: '3',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is the standard/default implemented protocol in RabbitMQ?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'SMTP',
      'AMQP 0.9.1',
      'MQTT',
      'HTTP',
      'AMQP 1.0'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What does AMQP stand for? ',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Alternate Message Queuing Protocol',
      'Advanced Message Query Protocol',
      'Advanced Message Queuing Protocol'
    ],
    correctAnswer: '3',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is the primary use of an exchange?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Inspecting the message body',
      'Routing a message to the correct queue',
      'Ensuring the message delivery to the consumer'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'The Routing Key is mainly what the Exchange looks for when deciding how to route the message to queues.',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'True',
      'False'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'The Routing Key is mainly what the Exchange looks for when deciding how to remove messages from a queue.',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'True',
      'False'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is the RabbitMQ Management Interface?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'A user-friendly interface that allows you to monitor and handle your RabbitMQ server from a web browser.',
      'A way to programmatically move messages between two different services.',
      'The IDE used while connecting and working with RabbitMQ.'
      ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Messages are sent between the producer and the consumer via a channel inside a connection. Do you need to set up a channel to be able to declare a queue?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  }
].sort(() => Math.random() - 0.5)

export const Quiz = {
  quizTitle: 'Quiz: Introduction',
  quizSynopsis: 'It\'s time to review what you\'ve learned about the basics of RabbitMQ and message queuing. This is just a practice quiz and the score does not count towards your final examination. You should simply see it as a good preparation for the theoretical exam at the end of this course. For the quiz to be marked as complete you need to score 85%. You can retake the quiz as many times as you need. Good Luck!',
  nrOfQuestions: '10',
  questions: questions,
  appLocale: {
    landingHeaderText: '<questionLength> questions | No time limit',
    startQuizBtn: 'Start quiz',
    resultPageHeaderText: 'You finished with a score of <correctIndexLength> out of <questionLength>.'
  }
}
