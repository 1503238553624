import React, { useReducer, createContext } from 'react'
import { ProgressReducer } from '../reducers/ProgressReducer'

const ProgressContext = createContext()

const initialState = {
  progress: [],
  sectionProgress: []
}

const ProgressContextProvider = (props) => {
  const [progress, dispatchProgress] = useReducer(ProgressReducer, initialState)
  return (
    <ProgressContext.Provider value={[progress, dispatchProgress]}>
      {props.children}
    </ProgressContext.Provider>
  )
}

export { ProgressContext, ProgressContextProvider }
