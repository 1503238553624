const questions = [
  {
    question: 'How does the RabbitMQ management interface collect data to show?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'The RabbitMQ management interface makes background queries to the HTTP API for RabbitMQ.',
      'The RabbitMQ management interface acts as a consumer and sets up a connection to all queues in the broker, and receives a copy of each message, and does calculations out of that.',
      'The RabbitMQ management interface records all events and stores them in a database.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Is it possible to view the total number of queued messages for all your queues in the RabbitMQ management interface?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'Is it possible to set up a connection to a consumer from the RabbitMQ management interface?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Yes',
      'No'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'It\'s possible to export definitions from the RabbitMQ Management interface. This definition file is like a backup. Why is this useful?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'The definition file stores all messages currently in all queues, into a database, which can be used when you need to restore messages.',
      'The feature of exporting and importing definitions can be used when you need to restore exchanges, queues, virtual hosts, policies, and users.',
      'Definitions control the persistence of messages and ensure broker stability.'
    ],
    correctAnswer: '2',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1',
  },
  {
    question: 'Keeping an eye on connection/channel opening/closure rates in RabbitMQ management Interface is recommended. Why is this a best practice?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'High connection and channel churn might lead to node exhaustion of resources.',
      'Action should always be taken when a connection is closed.',
      'A connection with no channel can lead to a channel leak.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'RabbitMQ connections and channels can be in different states. What does the flow state mean?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'The flow state indicates that the publishing rate has been restricted, to prevent RabbitMQ from running out of memory.',
      'The flow state indicates a stable flow through the channel.',
      'The flow state shows the number of messages per second through the channel.'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  }
].sort(() => Math.random() - 0.5)

export const Quiz = {
  quizTitle: 'Quiz: The Management Interface',
  quizSynopsis: 'It\'s time to review what you\'ve learned about the RabbitMQ Management interface. This is just a practice quiz and the score does not count towards your final examination. You should simply see it as a good preparation for the theoretical exam at the end of this course. For the quiz to be marked as complete you need to score 85%. You can retake the quiz as many times as you need. Good Luck!',
  nrOfQuestions: '6',
  questions: questions,
  appLocale: {
    landingHeaderText: '<questionLength> questions | No time limit',
    startQuizBtn: 'Start quiz',
    resultPageHeaderText: 'You finished with a score of <correctIndexLength> out of <questionLength>.'
  }
}
