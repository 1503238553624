import { Quiz as quiz95} from './Quiz95'
import { Quiz as quiz96} from './Quiz96'
import { Quiz as quiz97} from './Quiz97'
import { Quiz as quiz99} from './Quiz99'
import { Quiz as quiz100} from './Quiz100'

const q = [].concat(quiz95.questions).concat(quiz96.questions).concat(quiz97.questions).concat(quiz99.questions).concat(quiz100.questions).sort(() => Math.random() - 0.5)

const Examination = {
  quizTitle: 'Theoretical Exam',
  quizSynopsis: 'Ready to be examinated in this course and get your certificate? For the first part of your examination you have to complete a theoretical exam. In order to pass this exam and move on to the next part, a practical exam, you need to score at least 85%. If you fail you can restart and try again. Good luck!',
  nrOfQuestions: 20,
  questions: q,
  appLocale: {
    landingHeaderText: 'Quiz | <questionLength> questions | No time limit',
    startQuizBtn: 'Start exam',
    resultPageHeaderText: 'You finished with a score of <correctIndexLength> out of <questionLength>.'
  }
}

export { Examination }
