import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useHistory } from 'react-router-dom'
import { SignOut } from './User'

function Profile () {
  const history = useHistory()

  const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button 
      className='fal fa-ellipsis-v open-profile-drop' 
      type='button' 
      ref={ref} 
      aria-label='Toggle user actions'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    ></button>
  ))

  function signOut () {
    SignOut().then(() => {
      history.push('/')
    })
  }

  return (
    <div className='profile-wrap home-hidden'>
      <span className='user-profile-icon'>
        <i className="fas fa-user-circle"></i>
      </span>
      <Dropdown>
        <Dropdown.Toggle className='fal fa-ellipsis-v open-profile-drop' as={ProfileToggle} />
        <Dropdown.Menu className='profile-list profile-drop'>
          <Dropdown.Item href='#' onClick={() => signOut()}>Sign out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default Profile
