import React, { useContext, useState, useEffect } from 'react'
import { SectionContext } from '../contexts/SectionContext'
import { ProgressContext } from '../contexts/ProgressContext'
import { UserContext } from '../contexts/UserContext'
import { useHistory } from 'react-router-dom'
import { Subsections, getSubsectionDuration } from './Sections'
import { SubsectionClick } from './SectionChange'

function Subsection ({ sectionId }) {
  const [filteredSubsections, setFilteredSubsections] = useState([])
  const [sectionState, ] = useContext(SectionContext)
  const [progress, dispatchProgress] = useContext(ProgressContext)
  const [user, ] = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    (async () => {
      setFilteredSubsections(
          Subsections.filter(subsection => subsection.sectionId === sectionId)
      )
    })()
  }, [sectionId])

  function handleProgress (e, subsection) {
    e.stopPropagation()
    if (!progress.progress.includes(subsection.id)) {
      dispatchProgress({ type: 'AddProgress', subsection })
    }
    // else if (subsection.content !== 'quiz' || (subsection.content === 'quiz' && window.confirm('Do you want to reset the quiz?'))) {
    //   dispatchProgress({ type: 'DeleteProgress', subsection })
    // }
  }

  function returnMediaTypeIcon (sectionType) {
    let mediaTypeIcon = ''

    if (sectionType === 'video') {
      mediaTypeIcon = 'video'
    } else if (sectionType === 'quiz') {
      mediaTypeIcon = 'badge-check'
    } else if (sectionType === 'theoretical_exam' || sectionType === 'practical_exam') {
      mediaTypeIcon = 'graduation-cap'
    } else if (sectionType === 'practical_exercise') {
      mediaTypeIcon = 'laptop-code'
    } else {
      mediaTypeIcon = 'align-left'
    }

    return mediaTypeIcon
  }

  function Checkbox ({ subsection }) {
    return (
      <div className={subsection.id === sectionState.subsection.id ? 'custom-control active' : 'custom-control'}>
        <span className='custom-checkbox' onClick={(e) => { handleProgress(e, subsection) }}>
          {(subsection.content === 'quiz' || subsection.content === 'theoretical_exam' || subsection.content === 'practical_exam') && !progress.progress.includes(subsection.id) ? <div /> : <input className='section-checkbox' type='checkbox' aria-label={`Check ${subsection.title}`} checked={progress.progress.includes(subsection.id)} readOnly />}
          <span />
        </span>
        <div className='custom-control-inner'>
          <span className='name'>{subsection.title}</span>
          <span className='note'>
          {subsection.content === 'practical_exam' && !progress.progress.includes(57) ?
            (<span>Complete theoretical exam to unlock</span>) :
            (<span><i className={`fas fa-${returnMediaTypeIcon(subsection.content)}`}></i> {getSubsectionDuration(subsection.id)}</span>)
          }
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className='check-list'>
      {filteredSubsections.map(subsection => (
        <div className={(subsection.content === 'practical_exam' && !progress.progress.includes(57)) ? 'locked-subsection' : undefined} key={subsection.id} onClick={() => SubsectionClick(sectionState.subsection, subsection, user, progress, history)}>
          <Checkbox subsection={subsection} />
        </div>
      ))}
    </div>
  )
}

export default Subsection
