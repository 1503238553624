import React, { useState, useContext, useEffect, forwardRef } from 'react'
import Profile from './Profile'
import Dropdown from 'react-bootstrap/Dropdown'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { ProgressContext } from '../contexts/ProgressContext'
import { Rating, RatingModal } from './Rating'
import { Sections, Subsections } from './Sections'
import { LectureMenuContext } from '../contexts/LectureMenuContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


function Header () {
  const [progress, dispatchProgress] = useContext(ProgressContext)
  const [menu, setMenu] = useContext(LectureMenuContext)
  const [currentProgress, setProgress] = useState(0)
  const [resetProgressModal, setResetProgressModal] = useState(null)

  useEffect(() => {
    let userProgress = 0
    Sections.forEach(section => {
      userProgress += progress.sectionProgress[section.id - 1]
    })
    setProgress(userProgress)
  }, [progress])

  function confirmResetProgress() {
    dispatchProgress({ type: 'ResetProgress' })
    setResetProgressModal(false)
  }

  function ResetProgressModal() {
    return (
      <Modal class='popup-holder' dialogClassName='rating-lightbox' show={resetProgressModal} onHide={() => setResetProgressModal(false)}>
      <Modal.Header closeButton />
      <Modal.Body>
        <h2>Are you sure?</h2>
        <p>All your progress will be lost.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="float-left" onClick={confirmResetProgress}>Yes, reset all my progress</Button>
        <Button variant="secondary" className="float-right" onClick={() => setResetProgressModal(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
    )
  }

  const ProgressToggle = forwardRef(({ children, onClick }, ref) => (
    <button
      className='open-progress-drop'
      type='button'
      ref={ref}
      aria-label='Toggle progress details'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    ></button>
  ))

  return (
    <header id='header'>
      <Navbar className='navbar navbar-expand-lg navbar-light bg-light' bg='light' expand='lg'>
        <div className='container'>
          <Navbar.Brand className='brand-wrap'>
            <span>
              <div className='navbar-brand'>
                <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt='RabbitMQ Training logo' width='29' height='41' />
              </div>
            </span>
            <span className='slogan d-none d-lg-block'>Free RabbitMQ Training Course - <a href='https://cloudamqp.com' target='_blank' rel='noopener noreferrer'>by CloudAMQP</a></span>
          </Navbar.Brand>
          <Navbar.Toggle className='home-hidden open-filter-bar' onClick={() => setMenu(!menu)}>
            <span />
            <span />
            <span />
          </Navbar.Toggle>
          <Nav className='editor-actions-menu d-none d-md-flex home-hidden'>
            <Rating />
            <div className='progress-wrap'><span className='text d-none d-md-inline-block' id='your-progress'>Your progress</span>
              <div className='progress'>
                <div className='progress-bar' role='progressbar' style={{ width: `${(currentProgress / Subsections.length) * 100}%` }} aria-valuenow='20' aria-valuemin='0' aria-valuemax='100' />
              </div>
              <Dropdown>
                <Dropdown.Toggle as={ProgressToggle}></Dropdown.Toggle>
                <Dropdown.Menu className='progress-drop dropdown-menu'>
                  <div className='progress-drop-wrap'>
                    <span className='text'>{currentProgress} of {Subsections.length} completed.</span>
                    <button className='btn btn-link' onClick={() => setResetProgressModal(true) }>Reset progress</button>
                    <span className='note'>Complete the course to receive your certificate.</span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Profile />
          </Nav>
        </div>
      </Navbar>
      <RatingModal />
      <ResetProgressModal />
    </header>
  )
}

export default Header
