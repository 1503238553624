const questions = [
  {
    question: 'The RabbitMQ prefetch value is used to specify:',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'How many messages the broker can have in total in all queues.',
      'How long the consumer has to wait for a message.',
      'How many messages are being sent at the same time.'
    ],
    correctAnswer: '3',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What are the two prefetch options available in RabbitMQ?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Channel Prefetch and Consumer Prefetch',
      'RabbitMQ Prefetch and Client Prefetch',
      'Connection Prefetch and Broker Prefetch'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'What is true about dead lettering?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'A dead message can never be consumed.',
      'Queues attached to a dead letter exchange collect dropped messages and place them on a queue until they are able to be consumed by another consumer.',
      'A dead letter exchange is also used to manage all other queues and exchanges within an instance.',
      'A dead letter exchange should be set up when messages can\'t be lost with an expiring TTL or when the queue might reach its capacity.'
    ],
    correctAnswer: [2, 4],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'When is a message considered dead?',
    questionType: 'text',
    answerSelectionType: 'multiple',
    answers: [
      'When the TTL of a message expires.',
      'When the queue reaches its capacity.',
      'When the TTL of a message is larger than 1000.',
      'When a message is acknowledged two times.'
    ],
    correctAnswer: [1, 2],
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  },
  {
    question: 'RabbitMQ lets you define an exchange to apply logic to unroutable messages. What is the name of this exchange?',
    questionType: 'text',
    answerSelectionType: 'single',
    answers: [
      'Alternate exchange',
      'Unrouteable Exchange',
      'Exchange lost'
    ],
    correctAnswer: '1',
    messageForCorrectAnswer: 'Correct answer. Good job!',
    messageForIncorrectAnswer: 'Incorrect answer.',
    explanation: '',
    point: '1'
  }
].sort(() => Math.random() - 0.5)

export const Quiz = {
  quizTitle: 'Quiz: Optimization and Tuning',
  quizSynopsis: 'It\'s time to review what you\'ve learned about tuning the RabbitMQ broker and various configuration options. This is just a practice quiz and the score does not count towards your final examination. You should simply see it as a good preparation for the theoretical exam at the end of this course. For the quiz to be marked as complete you need to score 85%. You can retake the quiz as many times as you need. Good Luck!',
  nrOfQuestions: '5',
  questions: questions,
  appLocale: {
    landingHeaderText: '<questionLength> questions | No time limit',
    startQuizBtn: 'Start quiz',
    resultPageHeaderText: 'You finished with a score of <correctIndexLength> out of <questionLength>.'
  }
}
